<template>
    <div>
        <div class="input_label">{{ $t('documents') }}</div>

        <v-file-input
            v-show="false"
            ref="fileInputRef"
            id="file-input"
            :multiple="multiple"
            v-model="localFiles"
            :disabled="loading"
            @change="handleFileChange"
        />

        <div
            class="drop-zone"
            @dragover.prevent="handleDragover"
            @dragenter.prevent="handleDragover"
            @dragleave="handleDragleave"
            @drop="dropFile"
        >
            {{$t('drag_and_drop_documents_here_or')}}
            <span class="upload-button" @click.stop="triggerFileInputClick">{{$t('upload_file')}}</span>.
        </div>

        <v-list v-if="localFiles.length">
            <v-list-item
                v-for="(file, index) in localFiles"
                :key="index"
            >
                <v-list-item-icon>
                    <v-img
                        v-if="isImage(file)"
                        max-width="90"
                        max-height="60"
                        :src="getFilePreview(file)"
                    />
                    <v-icon v-else x-large>mdi-file-document-outline</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ file.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ (file.size / 1000).toFixed(1) }} KB</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                    <v-btn icon @click="removeFile(index)">
                        <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                </v-list-item-icon>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
export default {
    name: "FileUploadForm",
    props: {
        value: Array,
        loading: Boolean,
        multiple: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            localFiles: this.value || [],
            isDraggingOver: false,
        };
    },
    watch: {
        value(newFiles) {
            this.localFiles = newFiles || [];
        },
        isDraggingOver(val) {
            this.$el.querySelector(".drop-zone").classList.toggle("drag-over", val);
        }
    },
    methods: {
        triggerFileInputClick() {
            this.$refs.fileInputRef?.$el?.querySelector("input[type='file']")?.click();
        },
        handleFileChange() {
            if (!this.multiple) {
                this.localFiles = [this.localFiles];
            }
            let data = {}
            let files = []
            this.localFiles.forEach((file) => {
                data = {}
                data.url = URL.createObjectURL(file)
                data.name = file.name
                data.blob = file
                data.size = file.size
                data.type = file.type
                files.push(data)

            })

            this.$emit("input", files);

        },
        handleDragover(event) {
            event.preventDefault();
            event.dataTransfer.dropEffect = "copy";
            this.isDraggingOver = true;
        },
        handleDragleave() {
            this.isDraggingOver = false;
        },
        dropFile(event) {
            event.preventDefault();
            event.stopPropagation();

            this.isDraggingOver = false;

            if (!event.dataTransfer) {
                console.warn("DataTransfer недоступен.");
                return;
            }

            const items = event.dataTransfer.items;
            if (!items || !items.length) {
                console.warn("Файлы не были загружены. Проверь источник перетаскивания2.");
                return;
            }

            const droppedFiles = [];
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                if (item.kind === 'file') {
                    const file = item.getAsFile();
                    droppedFiles.push(file);
                }
            }

            if (droppedFiles.length === 0) {
                console.warn("Файлы не были загружены. Проверь источник перетаскивания.");
                return;
            }

            console.log("Полученные файлы:", droppedFiles);

            if (this.multiple) {
                this.localFiles = [...this.localFiles, ...droppedFiles];
            } else {
                this.localFiles = [droppedFiles[0]];
            }

            this.$emit("input", this.localFiles);
        },

        removeFile(index) {
            this.localFiles.splice(index, 1);
            this.$emit("input", this.localFiles);
            this.$emit("remove-file", index);
        },
        isImage(file) {
            return file.type.startsWith("image/");
        },
        getFilePreview(file) {
            return URL.createObjectURL(file);
        },
        async convertFileToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        dataURL64toBlob(dataURL) {
            const byteString = atob(dataURL.split(',')[1]);
            const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], { type: mimeString });
        }
    }
};
</script>

<style lang="scss">
.drop-zone {
    border: solid 2px #dcdcdc;
    border-radius: 8px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: border-color 0.3s ease;
    cursor: pointer;
}

.drop-zone:hover {
    border-color: #007EC9;
}

.drop-zone.drag-over {
    border-color: #28a745;
}

.upload-button {
    margin-left: 5px;
    color: #007EC9;
    cursor: pointer;
}
</style>
