<template>
    <v-container fluid>
        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogForm"
        >
            <v-form @submit.prevent="saveTemplate">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card flat>
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex mt-5">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ heading }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeDialogAdd" :title="$t('close')" color="primary">
                                                mdi-close
                                            </v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-row class="mt-5">
                                <v-col class="py-0" cols="12">
                                    <ValidationProvider ref="template" rules="required|min:2|max:255"
                                                        v-slot="{ errors, valid }">
                                        <TextInput
                                            id="template"
                                            :label="$t('template_name')"
                                            :error="!valid"
                                            :error-messages="errors"
                                            v-model="template"
                                            require
                                        ></TextInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12">
                                    <ValidationProvider ref="type_form" rules="required|min:1" v-slot="{ errors, valid }">
                                        <SelectInput
                                            clearable
                                            item-text="name"
                                            item-value="value"
                                            hide-details
                                            hide-label
                                            :items="typeFormItems"
                                            v-model="type_form"
                                            name="type_form"
                                            id="type_form"
                                            :hideLabel="false"
                                            :label="$t('type')"
                                            @input="getTemplateForm()"
                                        ></SelectInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" v-if="templateTableTypeItems.length > 0">
                                    <ValidationProvider ref="template_table_type" rules="required|min:1" v-slot="{ errors, valid }">
                                        <SelectInput
                                            clearable
                                            item-text="name"
                                            item-value="value"
                                            hide-details
                                            hide-label
                                            :items="templateTableTypeItems"
                                            v-model="template_table_type"
                                            name="template_table_type"
                                            id="template_table_type"
                                            :hideLabel="false"
                                            :label="$t('type_form')"
                                        ></SelectInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12">
                                    <FileUploadForm
                                        :files2="files"
                                        v-model="files" :multiple="false" :loading="loading"
                                        @remove-file="handleFileRemove"
                                    />
                                </v-col>

                            </v-row>
                        </v-card-text>
                        <v-card-actions class="px-4 py-7">
                            <btn type="submit" :disabled="invalid || loading" :loading="loading"
                                 :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                {{ $t('save') }}
                            </btn>
                            <btn
                                :disabled="loading"
                                class="ma-1 button_cancel"
                                plain
                                @click="closeDialogAdd"
                            >
                                {{ $t('cancel') }}
                            </btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                               :active="loading" class="mx-2"></v-progress-linear>

                        </v-card-actions>
                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";
import TextInput from "@/components/Form/TextInput.vue";
import Btn from "@/components/Form/Btn.vue";
import FileUploadForm from '@/components/FileUploadForm.vue';
import SelectInput from "@/components/Form/SelectInput.vue";

export default {
    name: 'TemplateForm',
    components: {
        ValidationProvider,
        ValidationObserver,
        TextInput,
        Btn,
        FileUploadForm,
        SelectInput
    },
    inject: ['forceRerender'],
    data() {
        return {
            dialogForm: true,
            heading: null,
            progress: 0,
            loading: false,
            language: null,
            template: null,
            files: [],
            type_form: null,
            typeFormItems: [
                {'name': this.$t('uveds'), value: "uved"},
                {'name': this.$t('deals'), value: "deal"},
                {'name': this.$t('tasks'), value: "task"}
            ],
            templateTableTypeItems: [],
            template_table_type: null,

        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang']),
        languages() {
            return this.listLanguages
        },
    },
    mounted() {
        this.language = this.languages[this.tab]
        this.checkCreate()
    },
    methods: {
        handleSave(fff) {
            console.log(fff)
        },
        handleFileRemove(index) {
            console.log(`Файл удалён:`);
        },

        checkCreate() {
            if (this.$route.name === "template.create") {
                this.heading = this.$t('template_creation')
            } else {
                this.heading = this.$t('template_editing')
                if (this.$route.params.id) {
                    this.getTemplate()
                }
            }
        },
        setLanguage(val) {
            this.language = this.languages[val]
            this.checkCreate()
        },
        closeDialogAdd() {
            this.$router.back()
        },
        async getTemplateForm() {
            this.loading = true
            let params = {};
            this.template_table_type = null;
            if (this.language) {
                params.language = this.language;
            }
            if (this.type_form) {
                params.type = this.type_form;
            }
            await this.$http
                .get(`admin/template_type`, {params: params})
                .then(res => {
                    this.templateTableTypeItems = res.body.data


                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_template'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async getTemplate() {
            this.loading = true
            let params = {};
            if (this.language) {
                params.language = this.language;
            }
            await this.$http
                .get(`admin/template/${this.$route.params.id}`, {params: params})
                .then(res => {
                    this.template = res.body.data.name
                    this.type_form = res.body.data.type
                    this.getTemplateForm();
                    this.template_table_type = res.body.data.type_id
                    this.files = [res.body.data.file]
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_template'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async saveTemplate() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.lang) {
                formData.append('language', this.lang)
            }
            if (this.template) {
                formData.append('template', this.template)
            }

            if (this.type_form) {
                formData.append('type', this.type_form)
            }
            if (this.template_table_type) {
                formData.append('template_table_id', this.template_table_type)
            }


            if (this.files && this.files.length > 0) {
                let is_image = false;
                for (let i in this.files) {
                    is_image = this.mimeTypeImage(this.files[i].type);
                    formData.append(`type_mime[${i}]`, this.files[i].type)
                    formData.append(`size[${i}]`, this.files[i].size)
                    formData.append(`file_name[${i}]`, this.files[i].name)
                    formData.append(`is_image[${i}]`, is_image ? 1 : null)
                    if (this.files[i].blob) {
                        this.files[i].blob = await this.convertBlobToBase64(this.files[i].blob)
                        if (this.files[i].blob) {
                            let blob = this.dataURL64toBlob(this.files[i].blob)
                            if (blob) {
                                formData.append(`upfiles[${i}]`, blob, this.files[i].name)
                            }
                        }
                    }

                    // formData.append(`upfiles[${i}]`, this.accompanying_documents_preview[i].blob)
                }
            }

            if (this.$route.params.id) {
                // Save
                await this.$http
                    .put(`admin/template/${this.$route.params.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('template_has_been_updated'))
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('template_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                // Add
                await this.$http
                    .post('admin/template', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('template_has_been_added'))
                        if (res && res.body && res.body.data && res.body.data.id) {

                            //this.forceRerender()
                        }
                        this.$router.push({
                            name: 'template'
                        })
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('template_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        }
    }
}
</script>
